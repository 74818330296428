import TopList, { ITopList } from "../model/Types/TopList";
import SocketConnector, { IEventData, ISearchPagination, ISearchResult } from "./SocketConnector"

export interface EventTopChangedData extends IEventData {
    model: ITopList    
}

export default class TopConnector extends SocketConnector {

    cb_top_changed:(data:EventTopChangedData)=>void
        
    constructor(socket:SocketIOClient.Socket)  {
        super(socket)
        this.verbose_name = "TopConnector:";
        this.controller_id = "player-rpc";
                
        this.cb_top_changed = (data) => {this.__log("cb_top_changed not used", data); };
        this.__setupCallbacks();
    }

    __setupCallbacks() {
        
        this.socket.on("TopListChanged", (data:EventTopChangedData) => {
            this.__log("TopListChanged", data);
            this.cb_top_changed(data);
        }); 
    };

    async doGetTopActivePlayersByRating():Promise<any> {
        return this.__call("get-top-active-players-by-rating", {});
    };

    async doGetTopActivePlayersByCredits():Promise<any> {
        return this.__call("get-top-active-players-by-credits", {});
    };

    async doGetTopActivePlayersByTournaments():Promise<any> {
        return this.__call("get-top-active-players-by-tournaments", {});
    };
}