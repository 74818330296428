import ModelListCommon from "./ModelListCommon";

import ModelCollectionTournament  from "./model/ModelCollectionTournament"
import TournamentConnector  from "./connectors/TournamentConnector"
import Game from "./model/Types/Game";
import Tournament, { ETournamentStatus , ETournamentStatusFilter, ITournament, TournamentUuid } from "./model/Types/Tournament";
import ArraySortedTournament from "./ArraySortedTournament";
import ModelCollection from "./model/ModelCollection";
import ModelArray from "./model/ModelArray";



export default class ModelListTournament extends ModelListCommon {
    
    static readonly EVENT_LIST_CANCELED  = "changed_canceled";
    static readonly EVENT_LIST_FINISHED  = "changed_finished";    

    models:ModelCollectionTournament

    modelsCanceled:ModelArray<Tournament>
    modelsFinished:ModelArray<Tournament>
    
    connector:TournamentConnector
    modelsSorted:ArraySortedTournament
            

    constructor(userConnector:any ) {           
        super(userConnector);
        this.models = new ModelCollectionTournament(); 

        this.modelsCanceled = new ModelArray<Tournament>();
        this.modelsFinished = new ModelArray<Tournament>();
                       
        this.connector = new TournamentConnector(this.socket);     
        this.connector.verbose = process.env.DEBUG_VERBOSE ? true : false;
        this.modelsSorted = new ArraySortedTournament(this.models);    
                               
        this.connector.setCallbackTournamentChanged( (data) => {
            if(!this.filled)           
                return;
                
            let tournament = new Tournament(data.tournament);
            this.doUpdateHistory(tournament);
                   
            this.models.itemUpdate(data.tournament);

            this.onListChanged();             
        });      
    }

    getEventData():any {
        return this.modelsSorted;
    }

    getConnector():TournamentConnector {
        return this.connector;
    }
            
    async doDataClear() {  
        super.doDataClear();      
        this.models.clear();
        this.onListChanged();

        this.doClearRecordsCanceled();
        this.doClearRecordsFinished();
    }    

    async doDataFetch() {             
        super.doDataFetch(); 

        this.models.clear();        
        this.doClearRecordsCanceled();
        this.doClearRecordsFinished();
        
        let modified = false;
                        
        let result = await this.connector.doGetListSearch({status:ETournamentStatusFilter.ACTUAL}, {page:0, pageSize:40});

        this.models.itemUpdateArray(result.models);
        modified = modified || !!result.models.length;
                 
        if(modified)
            this.onListChanged();             
    }

    doUpdateHistory(tournament:Tournament) {        
        // TODO add record not clear
        if(tournament.isFinished())
            this.doClearRecordsFinished();
            
        if(tournament.isCanceled())
            this.doClearRecordsCanceled();      
    }

    doClearRecordsFinished(emit=true) {    
        let models = this.modelsFinished;     
        models.clear();
        if(emit)       
            this.emit(ModelListTournament.EVENT_LIST_FINISHED, models)
    }

    doClearRecordsCanceled(emit=true) {    
        let models = this.modelsCanceled;     
        models.clear();
        if(emit)       
            this.emit(ModelListTournament.EVENT_LIST_CANCELED, models)
    }


    async doLoadMoreRecordsFinished(emit=true) {            
        let models = this.modelsFinished;            
        await this.doLoadMoreRecordsList(models, ETournamentStatus.FINISHED);        
        this.emit(ModelListTournament.EVENT_LIST_FINISHED, models)        
    }

    async doLoadMoreRecordsCanceled(emit=true) {     
        let models = this.modelsCanceled;                 
        await this.doLoadMoreRecordsList(models, ETournamentStatus.CANCELED);        
        this.emit(ModelListTournament.EVENT_LIST_CANCELED, models)
    }

    async doLoadMoreRecordsList(models:ModelArray<Tournament>, status: ETournamentStatus | ETournamentStatusFilter) {      
        let result = await this.doLoadMoreRecords(models.models, status)        
        let modelsEx = result.models.map( data => new Tournament(data));
        models.setCountTotal( result.pagination.totalCount );
        models.doExpand(modelsEx);    
        models.doFilterUnique();        
    }

    async doLoadMoreRecords(models:any[], status: ETournamentStatus | ETournamentStatusFilter) {
        let pageSize = 20;                
        let pagesLoaded = Math.floor(models.length / pageSize)
        let page = pagesLoaded;
        return await this.connector.doGetListSearch({ status}, {page, pageSize});        
    }

    async doLoadRecordsFinished(emit=true) {            
        let models = this.modelsFinished;            
        await this.doLoadRecordsList(models, ETournamentStatus.FINISHED);        
        this.emit(ModelListTournament.EVENT_LIST_FINISHED, models)        
    }

    async doLoadRecordsCanceled(emit=true) {     
        let models = this.modelsCanceled;                 
        await this.doLoadRecordsList(models, ETournamentStatus.CANCELED);        
        this.emit(ModelListTournament.EVENT_LIST_CANCELED, models)
    }

    async doLoadRecordsList(models:ModelArray<Tournament>, status: ETournamentStatus | ETournamentStatusFilter) {      
        let result = await this.doLoadRecords(models.models, status)        
        let modelsEx = result.models.map( data => new Tournament(data));
        models.clear();
        models.setCountTotal( result.pagination.totalCount );
        models.doExpand(modelsEx);    
        models.doFilterUnique();        
    }

    async doLoadRecords(models:any[], status: ETournamentStatus | ETournamentStatusFilter) {
        let pageSize = 20;                
        let pagesLoaded = Math.floor(models.length / pageSize)
        let page = pagesLoaded > 0 ? pagesLoaded - 1 : pagesLoaded;
        return await this.connector.doGetListSearch({ status}, {page, pageSize});        
    }

    onListChanged() {        
        this.modelsSorted.update(this.models, <string>this.playerUuid);
        this.emit(ModelListCommon.EVENT_DATA_CHANGED, this.getEventData());
    }  
}
