import SocketConnector, { IEventData, ISearchPagination, ISearchResult } from "./SocketConnector"
import { IAccount } from "../model/Types/Account"
import { IAccountLog } from "../model/Types/IAccountLog"


export interface IEventDataAccountChanged extends IEventData {
    account: IAccount
    delta?:  IAccountLog
}

export interface IEventDataAccountSelected {
    account: IAccount    
}


export default class AccountConnector extends SocketConnector {

    cb_account_select:(data:IEventDataAccountSelected)=>void
    cb_account_changed_selected:(data:IEventDataAccountSelected)=>void
    cb_account_changed_any:(data:IEventDataAccountChanged)=>void
    cb_on_message:(data:any)=>void

    constructor(socket:SocketIOClient.Socket)  {
        super(socket)        
        this.verbose_name = "AccountConnector:";
        this.controller_id = "account-rpc";
        
        this.cb_account_select           = (data) => {this.cb_account_changed_selected(data); };
        this.cb_account_changed_selected = (data) => {this.__log("cb_account_changed_selected not used", data); };
        this.cb_account_changed_any      = (data) => {this.__log("cb_account_changed_any not used", data); };
        
        this.cb_on_message = (data) => {this.__log("cb_on_message not used", data); };
        this.__setupCallbacks();
    }
    
    
    __setupCallbacks() {
        this.socket.on("message", (data:any) => {
            this.__log("message", data);
            this.cb_on_message(data);
        });        

        this.socket.on("accountChanged", (data:IEventDataAccountChanged) => {
            this.__log("accountChanged", data);
            this.cb_account_changed_any(data);
            if(data.account === undefined)
                return;

            if(!data.account.isSelectedByPlayer) 
                return this.__log("Not active account changed!")
    
            this.__log("accountChangedSelected", data)
            this.cb_account_changed_selected(data)
        });

        // Redundant 
        this.socket.on("accountSelected", (data:IEventDataAccountSelected) => {
            this.__log("accountSelected", data);
            this.cb_account_select(data);
        });
                           
    };
    
    async doGetListSearch(search:any, pagination:ISearchPagination):Promise< ISearchResult<IAccount > > {
        return  this.__call("get-list", {
            pagination: pagination,
            search: search
        });            
    };
    
    async doHistorySearch(account_uuid:string | string[], search:any, pagination:ISearchPagination):Promise< ISearchResult<IAccountLog> > {         
        return this.__call(
            "history-list", 
            (
                Array.isArray(account_uuid)?
                    {
                        account_uuids: account_uuid,
                        pagination: pagination,
                        search: search
                    }:
                    {
                        account_uuid: account_uuid,
                        pagination: pagination,
                        search: search
                    }
            )
        );
    }

    async doView(account_uuid:string):Promise<IAccount> {        
        return this.__call("view", {account_uuid});
    }

    async doSelect(account_uuid:string):Promise<IAccount> {
        return this.__call("select", {account_uuid});
    }

    async doLogView(account_log_id:string):Promise<IAccountLog> {
        return this.__call("log-view", {account_log_id});
    }
}
