
import type {} from "socket.io-client";
import TransportInterface from "../transport/TransportInterface";
import logger from "../utility/logger";
import SocketConnector, { IUserInfo } from "./SocketConnector";

export default class UserConnector extends SocketConnector {    
    transport:TransportInterface
    timeout:number
    rpcUrl:string

    onAuthChanged: ()=>void             // авторизация сменилось надо рестартнуть socket
    onUserInfo: (data:IUserInfo)=>void  // достоверные данные об игроке    
    verbose:boolean
    //myUuid:string| null    
    sessionId:string | null

    debugEmulateFailer: boolean

    static readonly EVENT_USER_INFO    = "userInfo"
    static readonly EVENT_AUTH_CHANGED = "authChanged"  // need reconnection               
    static readonly EVENT_SESSION_TOKEN_CHANGED = "sessionChanged" //no need to reconnect
    static readonly EVENT_SESSION_NEED_CHECK = "sessionNeedCheck" // need reload session_id from sessionStore

    constructor(socket:SocketIOClient.Socket, transport:TransportInterface)  {
        // onAuthChanged fire event
        super(socket)

        this.debugEmulateFailer = false
        this.verbose_name = "UserConnector:";
        this.emit_name = "not used";

        this.transport = transport;

        this.timeout = 15000;
        this.rpcUrl = '/service-rpc';
        this.onAuthChanged = ()=>{}
        this.onUserInfo    = (data:IUserInfo)=>{};
        this.verbose = false;        
        this.myUuid = undefined;
        
        this.sessionId = null        
        

        // this.socket.on("login", (data:any) => {
        //     if(this.verbose)
        //         logger.log("from socket: login", data);
        //     this._sessionIdFetchLocal();                        
        // });

        this.socket.on("logout", async (data:any) => {
            if(this.verbose)
                logger.log("from socket: logout", data);            
            await this.setSessionId(null);
        });
        this.socket.on("session_changed", async (data:any) => {
            // ключ сессиии изменился - заново попробуем его 
            if(this.verbose)
                logger.log("from socket: session_changed", data);
            this._sessionIdFetchLocal();  
        });

        // this.socket.on('connect', ()=>{            
        //     this.doUserInfoGet(); //send cookies
        // });

        // вызывается после connect от сервера приходит 
        this.socket.on("user_info", async (data:IUserInfo) => { 
            logger.debug("from socket: On user info", data) 
            
            if(!data.session)
                return; // old version not used 

            // получаем последную версию session_id
            // на случай если другая вкладка её обновила
            // получим чуть позже
            this._sessionIdFetchLocal();                 

            // если у нас свежее версия чем у сокета то переподключаемся
            if(this.sessionId != data.session.id) {
                // if(data.session.id) { // тут придется рвать соедиение смена сессии                    
                //     this.__onAuthChanged();                                
                // } else {
                if(await this._sendAuthorize())
                    return; // we expect new user_info event
                //}
            }            
                 
            this.myUuid  = data.uuid;                        
            this.__onUserInfo(data);          
        });

        // this.socket.on("unauthorised", async (data:any) => {

        //     if(this.verbose)
        //         logger.log("from socket: unauthorised", data);

        //     if(!this.myUuid)
        //         return;    

        //     //await this.setSessionId(null);            
        //     //this.__onAuthChanged();
        // });        
    }
    
    /**      
     * @returns true if authorize sended to server
     */

    async _sendAuthorize():Promise<boolean> {        
        if(this.verbose)
            this.__log("sendAuthorize session_id = ",this.sessionId)
     
        if(!this.socket.connected)
            return false;

        try {
            await this.emitPromise("authorize", {sessionId:this.sessionId})
            return true;
        }
        catch(e) {
            this.__log("Authorize err",e)
            return false;
        }
    }

    // Мы получили данные об игроке - отправляем наверх
    __onUserInfo(data:IUserInfo) {                
        if(this.verbose)
            logger.log("user connector emit user info ", data );        
        this.emit(UserConnector.EVENT_USER_INFO, data);
        this.onUserInfo(data);
    }
    
    __onAuthChanged() {        
        if(this.verbose)
            logger.log("UserConnector:","onAuthChanged! Start reconnection");

        this.myUuid = undefined;                                
        this.emit(UserConnector.EVENT_AUTH_CHANGED);        
        if(this.onAuthChanged)
            this.onAuthChanged();

        // TODO sed autorize
        this.socket.close();                                 
        setTimeout( ()=>{            
            this.socket.open();
        } , 100); 
    };

    async __call(method:string, params?:object):Promise<any> {
        return this.wrapLoading( ()=>{
            return this.__callAjaxRpc(method, params);
        });        
    }

    private async __callAjaxRpc(method:string, params?:object):Promise<any> {  
        if(this.verbose)
            logger.log("UserConnector:","emit serviceRpc", method, params);

        if (this.debugEmulateFailer) {
            if (Math.round(Math.random() * 5) < 4) {
                throw new Error("auth error")
            } 
        }
     
        let response = await this.transport.post(this.rpcUrl, {            
                jsonrpc:'2.0',
                method: method,
                params: {
                    form:params
                },
                id:"1"
            }, {
                timeout:this.timeout,  
                headers: {
                    "Authorization" : "bgsession "+this.sessionId,
                }
            }
        )

        if (method === undefined)
            method = "";

        let data = response.data;        

        if(data.error) {            
            if(this.verbose)
                logger.log("UserConnector:","error ("+method+")", data.error);
            let err = new Error(data.error.message);
            //текущая архитектура серверного взаимодействия некорректно использует exception'ы в качестве метода коммуникации (внутри catch нам теперь нужно знать ПОЧЕМУ сервер ответил ошибкой (что, вообще, является вполне корректным ответом здесь в частности на попытку регистрации аккаунта на уже занятую кем-то почту)), поэтому правим это поведение тайком протаскивая нужные нам данные:
            (<any>err).opaque_data = data;
            (<any>err).jsonError = data.error; 
            throw err;        
        }

        let result = data.result;

        if(this.verbose)
            logger.log("UserConnector:","result ("+method+")", result);
                               
        this.checkSessionData(result);

        return result;
    };

    checkSessionData(result:IUserInfo) {
        this.__proceedSession(result);    
        this.__proceedCookies(result);
    }
        
    __proceedCookies(result:IUserInfo) {        
        if(result.cookie === undefined)
            return;
        if(result.cookie.length === 0)
            return;              

        //this is obosolete
        //this.__onAuthChanged();
    };

    __proceedSession(result:IUserInfo) {
        interface ISessionInfo {
            id:string
            name:string
            params:any
        };
        let sessionInfo = result.session;        
        if(!sessionInfo)
            return;            
        if(sessionInfo.id == this.sessionId)    
            return; //same session             
        this.setSessionId(sessionInfo.id) //await       
    };

    async _sessionIdFetchLocal() { 
        this.emit(UserConnector.EVENT_SESSION_NEED_CHECK, this.sessionId);                              
    }

    /**
     * Set and save session id     
     * @param value       
     * @returns bool True if auth sended
     */
    async setSessionId(value:string | null):Promise<boolean> {
        if(this.sessionId == value)             
            return false;

        this.__log("Changing session from ",this.sessionId, ' to ', value);
        
        let extraHeaders = {}
        if(value) {
            extraHeaders = {
                'Authorization': 'bgsession '+value
            }
        }
        this.socket.io.opts.transportOptions = {
            polling: {
                extraHeaders
            }
        }
                                
        this.sessionId = value;
        if(this.verbose)
            this.__log("Session id changed to ", this.sessionId);

        this.emit(UserConnector.EVENT_SESSION_TOKEN_CHANGED, this.sessionId);
        return this._sendAuthorize();        
    }
        
    clientTraceMessage(message:string) {
        this.socket.emit("client_trace", message);
    };
    
    setCallbackUserInfo(cb:(data:IUserInfo)=>void) { 
        this.onUserInfo = cb;
    };

    // ProninE:
    // теперь не испоьлзуется, в место этого - сервер по socket присылает событие 
    // 'userInfo' при авторизации сокета
    //
    // async doUserInfoGet():Promise<IUserInfo> {
    //     let result:IUserInfo =  await this.__call("user-info-get", {});        
    //     if(this.verbose) {
    //         logger.log(" User info ajax  ", result);            
    //     }        
    //     return result;  
    // };         

    async doLogin(username:string, password:string) {        
        return this.__call("login", {
            username:username,
            password:password
        });
    };
   
    async doLoginDemo() {
        return this.__call("login-demo", {});
    };
        
    async doLogout() {
        return this.__call("logout", {});
    };

    async doSignUp(username:string, password:string, email:string) {            
        return this.__call("signup", {
            "username": username,
            "password": password,
            "email":    email,
        });
    };

    async doSignUpBot(username:string, password:string) {            
        const email = username+"@bot.backgammon.com"; 
        return this.__call("signup", {
            "username": username,
            "password": password,
            "email":    email,
            "allowAutoActivate": true,
        });
    };

    async doRequestPasswordReset(email:string) {
        return this.__call("request-password-reset", {
            "email":    email
        });
    };

    async doPasswordReset(token:string, password:string) {
        return this.__call("password-reset", {
            "token": token,
            "password":password
        });
    };

    async doVerifyEmail(token:string) {
        return this.__call("verify-email", {
            "token": token
        });
    };

    async doResendVerificationEmail(email:string) {
        return this.__call("resend-verification-email", {
            "email": email
        });
    };
}

