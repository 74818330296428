import ModelListCommon from "./ModelListCommon";

import ModelCollectionGame  from "./model/ModelCollectionGame"
import GameConnector  from "./connectors/GameConnector"
import Game from "./model/Types/Game";
import GamesSorted from "./ArraySortedGame";
import ModelArray from "./model/ModelArray";



export default class ModelListGame extends ModelListCommon {

    static readonly EVENT_HISTORY_GAMES = "history_games";

    games:ModelCollectionGame
    gameConnector:GameConnector
    gamesSorted:GamesSorted

    modelsLog:ModelArray<Game>
    

    constructor(userConnector:any ) {           
        super(userConnector);
        this.games = new ModelCollectionGame();
        this.gameConnector = new GameConnector(this.socket);     
        this.gameConnector.verbose = process.env.DEBUG_VERBOSE ? true : false;
        this.gamesSorted = new GamesSorted(this.games);
        
        this.modelsLog = new ModelArray<Game>();
                                       
        this.gameConnector.cb_game_list_changed = (data) => { 
            if(!this.filled)           
                return;

            this.games.itemUpdate(data.game);
            this.onGameListChanged();
            
            let game = new Game(data.game);  
            if(game.isDead() && game.isPlayerIn(userConnector.myUuid))
                this.doClearHistoryGame(); //todo add game to history
        };        
    }

    getEventData():GamesSorted {
        return this.gamesSorted;
    }

    getConnector():GameConnector {
        return this.gameConnector;
    }
            
    async doDataClear() {  
        super.doDataClear();      
        this.games.clear();        
        this.onGameListChanged();
        this.doClearHistoryGame();
    }    

    async doDataFetch() {             
        super.doDataFetch(); 

        let result;
        this.games.clear();
        let modified = false;
        let [ result1 , result2 ] = await Promise.all( [
            this.gameConnector.doGetListSearch({ status: "active", ownership: "my" }, {page:0, pageSize:20}),
            this.gameConnector.doGetListSearch({ status: "active" }, {page:0, pageSize:300}),
        ]);        
        this.games.itemUpdateArray(result1.models);        
        this.games.itemUpdateArray(result2.models); 

        modified = modified || result1.models.length > 0;
        modified = modified || result2.models.length > 0;

        if(modified)
            this.onGameListChanged();                     
    }

    onGameListChanged() {                                
        this.gamesSorted.update(this.games, <string>this.playerUuid, this.playerCurrency);
        this.emit(ModelListCommon.EVENT_DATA_CHANGED, this.getEventData());
    }


    async doClearHistoryGame(emit=true) {    
        let models = this.modelsLog;     
        models.clear();
        if(emit)       
            this.emit(ModelListGame.EVENT_HISTORY_GAMES, models)
    }


    async doLoadMoreHistoryGame(emit=true) {     
        
        let models = this.modelsLog;              
        await this.doLoadMoreHistory(models);        
        if(emit)       
            this.emit(ModelListGame.EVENT_HISTORY_GAMES, models)
    }


    async doLoadMoreHistory(models:ModelArray<Game>) {      
        let result = await this.doLoadMoreRecords(models.models)                
        models.setCountTotal( result.pagination.totalCount );
        let modelsEx = result.models.map( data => new Game(data));
        models.doExpand(modelsEx);
        models.doFilterUnique();        
    }

    async doLoadMoreRecords(models:any[]) {
        let pageSize = 20;                
        let pagesLoaded = Math.floor(models.length / pageSize)
        let page = pagesLoaded;
        return await this.gameConnector.doGetListSearch({
            ownership: "my",
            //status?: EGameStatus | "all" | "active" | "finished" | "canceled"
            //status: "finished"
            status : 'all',
        },{page, pageSize});        
    }
}
