import ModelListCommon from "./ModelListCommon";

import ModelCollectionTrade  from "./model/ModelCollectionTrade"
import TradeOffer from "./model/Types/TradeOffer";
import ArraySortedTrade from "./ArraySortedTrade";
import TradeConnector from "./connectors/TradeConnector";




export default class ModelListTrade extends ModelListCommon {

    models:ModelCollectionTrade
    connector:TradeConnector
    modelsSorted:ArraySortedTrade
    timestampShift:number

    timerUpdateGift?:NodeJS.Timeout
    
    constructor(userConnector:any, tradeConnector:TradeConnector ) {           
        super(userConnector);
        this.models = new ModelCollectionTrade();
        this.connector = tradeConnector; //new TradeConnector(this.socket); 
        this.modelsSorted = new ArraySortedTrade(this.models); 
        this.timestampShift = 0;  
        this.timerUpdateGift = undefined;
        
                               
        this.connector.cb_trade_offer_changed = (data: any) => {
            if(!this.filled)           
                return;

            if (Array.isArray(data.model) === true)
                data.model.forEach((model:any) => {
                    this.models.itemUpdate(model);
                });
                    else
                this.models.itemUpdate(data.model);
                
                
            this.onListChanged(data.meta);            
        }   
    }

    getEventData():any { return this.modelsSorted; }
    getConnector():TradeConnector { return this.connector; }
            
    async doDataClear() {  
        super.doDataClear();      
        this.models.clear();
        this.onListChanged();
    }    

    async doDataFetch() {             
        super.doDataFetch(); 

        this.models.clear();        
        let result = await this.connector.doGetListSearch({}, {page:0,pageSize:200});
        this.models.itemUpdateArray(result.models)       
        if(result.models.length)
             await this.onListChanged(result.meta);
    }

    async onListChanged(meta?:any) {    
        if(meta)
            this.updateTimestampShift(meta.timestamp);  
                    
        this.modelsSorted.update(this.models, this.getTimestamp() );
        this.emit(ModelListCommon.EVENT_DATA_CHANGED, this.getEventData());

        this.updateGiftReload();        
    }

    getTimestamp() {
        return Math.floor(Date.now() / 1000) + this.timestampShift;
    }

    getTimeToGift() { // return seconds to       
        let serverTimestamp = this.getTimestamp();
        let timeToUpdateGiftMin = 0;
        this.models.forEachAsync( (tradeOffer) => {
            if(!tradeOffer.isGift())
                return;
            
            if(!tradeOffer.valid_after_timestamp)
                return;
                    
            const timeToWait = tradeOffer.valid_after_timestamp - serverTimestamp;
            if(timeToWait <= 0) 
                return;

            if(timeToUpdateGiftMin && timeToUpdateGiftMin < timeToWait)
                return;

            timeToUpdateGiftMin = timeToWait;      
        });
        return timeToUpdateGiftMin;
    }

    updateTimestampShift(serverTimestamp:number) {
        let ts = this.getTimestamp();
        this.timestampShift += serverTimestamp - ts;
    }

    async updateGiftReload() {
        let serverTimestamp = this.getTimestamp();
        let timeToUpdateGiftMin = this.getTimeToGift();

        if(!timeToUpdateGiftMin)
            return;
        
        if(this.timerUpdateGift) {
            clearTimeout(this.timerUpdateGift);
            this.timerUpdateGift = undefined;
        }

        this.timerUpdateGift = setTimeout(()=>{
            this.timerUpdateGift = undefined;                
            this.onListChanged(); // force recalculate 
        } , (timeToUpdateGiftMin+1) * 1000 );               
    }


}
