import SocketConnector from "./SocketConnector"

export default class SupportConnector extends SocketConnector {
    constructor(socket:SocketIOClient.Socket)  {
        super(socket)
        this.verbose_name = "SupportContact:";
        this.controller_id = "support-contact-rpc";
    }

    async sendMessage(message:string, email:string) {
        return this.__call("send-message", {message: message, email: email, info: navigator.userAgent});
    }
}