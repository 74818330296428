import SocketConnector, { ISearchPagination, ISearchResult } from "./SocketConnector"
import { EGameType, EGameStatus,  EGameKind, IGame, GameUuid, IGameWithPlayersAndCurrency, EGameSpeed } from "../model/Types/Game";
import { EBotSkillLevel } from "../model/Types/IPlayerSettings";




export interface IGameCreateOptions {
    game_type:EGameKind;
    time_for_one_step?:number;
    speed_level?:EGameSpeed;
    enable_mars?:boolean;
    enable_koks?:boolean
    enable_jakobi?:boolean
    enable_crawford?:boolean

    double_cube_enable?:boolean
    double_cube_auto?:boolean
    double_cube_auto_limit?:number
    double_cube_biver?:boolean
    double_cube_rakun?:boolean

    enable_paritet?:boolean
    max_score?:number
    bet_price?:number
    bet_currency?:string // code

    caption? :number
    enable_watchers? :boolean
    private? :boolean
    password? :string

    opponent_rating_score_min?:number
    opponent_rating_score_max?:number
    enable_chat?:boolean;
}


export interface IGameCreateOptionsTrain extends IGameCreateOptions  {
    skill_level:EBotSkillLevel
}

interface IGameSearch {
    ownership?: "all" | "my" | "not_my"
    status?: EGameStatus | "all" | "active" | "finished" | "canceled"
    joinable?: "all" | "true"
    type_id?:EGameType | "all"
}


interface IEventDataGameStatusChanged {
    game: IGameWithPlayersAndCurrency
}

export default class GameConnector extends SocketConnector {

    cb_game_list_changed:(data:IEventDataGameStatusChanged)=>void
    cb_my_game_started:(data:IEventDataGameStatusChanged)=>void
    cb_my_game_canceled:(data:IEventDataGameStatusChanged)=>void
    cb_revenge_request:(data:IEventDataGameStatusChanged)=>void

    constructor(socket:SocketIOClient.Socket)  {
        super(socket)
        this.verbose_name = "GameConnector:";
        this.controller_id = "game-rpc";


        this.cb_game_list_changed = (data) => {this.__log("cb_game_list_changed", data); };
        this.cb_my_game_started = (data)   => {this.__log("cb_my_game_started", data); };
        this.cb_my_game_canceled = (data)  => {this.__log("cb_my_game_canceled", data);};
        this.cb_revenge_request = (data)   => {this.__log("cb_revenge_request", data);};

        this.__setupCallbacks();
    }


    __setupCallbacks() {
        this.socket.on("gameStatusChanged", (data:IEventDataGameStatusChanged) => {
            this.__log("gameStatusChanged", data);
            if (process.env.STARZ_GAME !== "1" || this.myCurrency == data.game.currency.id) {
                this.cb_game_list_changed(data);
                var is_my = false;
                var status;
                try {
                    is_my = (data.game.player_a_uuid == this.myUuid || data.game.player_b_uuid == this.myUuid);
                    status = data.game.status;
                } catch (e) {
                }
                if (!is_my)
                    return;

                if (status == EGameStatus.IN_PLAY) {
                    this.__log("My game Started " + data.game.uuid, data);
                    this.cb_my_game_started(data);
                } else if (status == EGameStatus.CANCELED) {
                    this.__log("My game canceled " + data.game.uuid, data);
                    this.cb_my_game_canceled(data);
                } else if (status == EGameStatus.WAIT_PLAYERS && data.game.player_b_uuid == this.myUuid) {
                    this.__log("On game revenge request " + data.game.uuid, data);
                    this.cb_revenge_request(data);
                }
            }
        });
    };


    async doCreate(options:IGameCreateOptions) {
        return this.__call("create", options);
    };

    async doTrain(options:IGameCreateOptionsTrain) {
        return  this.__call("train", options);
    };

    async doJoin(game_uuid:GameUuid) {
        return this.__call("join", { game_uuid: game_uuid});
    };



    /**
     * Get list of games
     *  My games finished or active
     */
    async doGetListSearch(search:IGameSearch, pagination:ISearchPagination): Promise<ISearchResult< IGame > > {
        return this.__call("list", {
            pagination: pagination,
            search: search
        });
    };


    async doView(game_uuid:GameUuid):Promise<IGame> {
        return this.__call("view", {
            game_uuid: game_uuid
        });
    };


    /**
     *
     * Create game revenge
     *
     * you need wait while game will started or canceled
     * @param game_old_uuid UUId of previous game
     * @param cb function(e,r)
     *
     *
     */
    async doRevengeCreate(game_old_uuid:GameUuid) {
        return this.__call("create-revenge", {
            game_uuid: game_old_uuid
        });
    };


    async doRevengeConfirm(game_uuid:GameUuid) {
        return this.__call("confirm", {
            game_uuid: game_uuid
        });
    };


    async doRevengeReject(game_uuid:GameUuid) {
        this.doCancel(game_uuid);
    };


    /**
     * Cancel game if it possible
     * @param game_uuid UUId of  game
     * @param cb function(e,r)
     */
    async doCancel(game_uuid:string) {
        return this.__call("cancel", {
            game_uuid: game_uuid
        });
    };
}