import ModelListCommon from "./ModelListCommon";

import UserConnector from "./connectors/UserConnector";
import TopConnector from "./connectors/TopConnector";
import Player from "./model/Types/Player";
import { IUserInfo } from "./connectors/SocketConnector";
import ArraySortedPlayer from "./ArraySortedPlayer";


export default class ModelListTop extends ModelListCommon {

    static readonly EVENT_TOP_ACTIVE_PLAYERS_BY_RATING_CHANGED      = "topActivePlayersByRatingChanged";
    static readonly EVENT_TOP_ACTIVE_PLAYERS_BY_CREDITS_CHANGED     = "topActivePlayersByCreditsChanged";
    static readonly EVENT_TOP_ACTIVE_PLAYERS_BY_TOURNAMENTS_CHANGED = "topActivePlayersByTournamentsChanged";

    connector:TopConnector
    models:ArraySortedPlayer
                
    constructor(userCon:UserConnector, topConnector:TopConnector ) {          
        super(userCon);
        this.connector = topConnector;      
        this.models = new ArraySortedPlayer();      
    } 

    getEventData():any { return this.models; }
    getConnector():TopConnector { return this.connector; }
            
    async doDataClear() {  
        super.doDataClear();      
        this.models.clear();
    }    

    async doDataFetch() {             
        super.doDataFetch();         
    }

    async getTopCredits( ) {
        let result = await this.connector.doGetTopActivePlayersByCredits( );
        return result;
    }   

    async getTopTournaments( ) {
        let result = await this.connector.doGetTopActivePlayersByTournaments( );
        return result;
    } 
    
    async getTopRatings( ) {
        let result = await this.connector.doGetTopActivePlayersByRating( );
        return result;
    } 
}
