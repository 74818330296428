/**
 * TournamentConnector class.
 *
 * About classes  https://learn.javascript.ru/class-inheritance
 * @inheritDoc
 */

import SocketConnector, { ISearchPagination, ISearchResult } from "./SocketConnector"
import Tournament, {TournamentUuid, ITournament, ITournamentWithPlayerTournaments,ITournamentWithPlayerTournamentsAndWinner, ITournamentWithAll} from "../model/Types/Tournament";


interface IGetTimeResult {
    
    meta: {
        timestamp:number
    },
    time: {
        tournament_start:number,
        tour_end:number,
    } 
}

interface IEventDataTournamentChanged {
    tournament: ITournamentWithPlayerTournamentsAndWinner
    //[], [ "playerTournaments","playerTournamentWinner"]     
}

export default class TournamentConnector extends SocketConnector {

    static readonly PLAYERS_COUNT_MIN = 2;
    static readonly PLAYERS_COUNT_MAX = 64;

    cb_state_changed: (data:IEventDataTournamentChanged)=>void

    constructor(socket:SocketIOClient.Socket)  {
        super(socket)
        this.verbose_name = "TournamentConnector:";
        this.controller_id = "tournament-rpc";
                           
        this.cb_state_changed = (data) => {this.__log("cb_tournament_changed", data); };
        
        this.__setupCallbacks();
    }

    __setupCallbacks() {            
        this.socket.on("tournamentChanged", (data:IEventDataTournamentChanged) => {
            this.__log("tournamentChanged", data);
            this.cb_state_changed(data);
        });
    };


    /**
     * join tournament
     * @param uuid uuid of tournament
     * @param cb function
     */
    async doJoin(uuid:TournamentUuid) {
        return this.__call("join", { uuid });
    };

    /**
     * view details
     * @param uuid uuid of tournament
     * @param cb function
     */
    async doView(uuid:TournamentUuid):Promise<ITournamentWithAll> {
        return this.__call("view", { uuid});
    };


    /**
     * Get list of tournaments
     *
     * @param search
     * @param search.status  int | "nearest" - open future| "actual" - open and in play| "active" - in play | "completed"
     * @param pagination
     * @param pagination.page   int
     * @param pagination.pageSize  int
     * @param cb function
     * 
     * 
     */
    
    async doGetListSearch(search:ITournament | any, pagination:ISearchPagination): Promise< ISearchResult < ITournamentWithPlayerTournaments > > {
        return this.__call("list", {
            pagination: pagination,
            search: search
        });
    };


    /**
     * Get list of tournaments
     */     
    //  with currency bet win 
    async doGetListHistory(search:ITournament | any, pagination:ISearchPagination):Promise< ISearchResult< ITournament >> {
        return this.__call("history", {
            pagination: pagination,
            search: search
        });
    };


    /**
     * Get time till next tournament or tour
     *
     */
    async doGetTimeLeft(): Promise< IGetTimeResult > {
        return this.__call("get-time-left", {})
    }

    /**
     * callback when game list changed.
     * @param cb
     */
    setCallbackTournamentChanged(cb:(data:IEventDataTournamentChanged)=>void) {
        this.__log("setCallbackTournamentChanged");
        this.cb_state_changed = cb;
    };
}
