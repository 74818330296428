import TradeOffer, { ITradeOffer, ITradeOfferWithCurrency , TradeOfferUuid } from "../model/Types/TradeOffer";
import SocketConnector, { IEventData, ISearchPagination, ISearchResult } from "./SocketConnector"
import logger from "../utility/logger";



export interface EventTradeOfferChangedData extends IEventData {
    model: ITradeOfferWithCurrency    
}

export default class TradeConnector extends SocketConnector {

    cb_trade_offer_changed:(data:EventTradeOfferChangedData)=>void
        
    constructor(socket:SocketIOClient.Socket)  {
        super(socket)
        this.verbose_name = "TradeConnector:";
        this.controller_id = "trade-rpc";
                
        this.cb_trade_offer_changed = (data) => {this.__log("cb_trade_offer_changed not used", data); };
        this.__setupCallbacks();
    }

    __setupCallbacks() {
        this.socket.on("tradeOfferChanged", (data:EventTradeOfferChangedData) => {
            this.__log("tradeOfferChanged", data);
            this.cb_trade_offer_changed(data);
        });
        
    };
  
    async doGetListSearch(search:ITradeOffer | {}, pagination:ISearchPagination):Promise< ISearchResult < ITradeOfferWithCurrency >> {        
        return this.__call("get-list", {
            pagination: pagination,
            search: search
        });
    };
    
    async doExecute(uuid:TradeOfferUuid) {
        return this.__call("execute", { uuid });
    };

}

