
export default class SharedStorage {
    localStorageFailOver: { [key: string]: any }
    storage: Storage

    constructor( storage: Storage) {
        this.localStorageFailOver = {};
        this.storage = storage
    }


    set(name: string, value: any) {
        try {
            this.storage.setItem(name, JSON.stringify( value ) );
        } catch (e) {
            this.localStorageFailOver[name] = value;
        }
    }

    get(name: string) {
        try {
            let value = this.storage.getItem(name);
            if (!value)
                return null;
            return JSON.parse(value);
        } catch (e) {
            return this.localStorageFailOver[name];
        }
    }

    remove(name: string) {
        try {
            this.storage.removeItem( name );
        } catch (e) {
            delete this.localStorageFailOver[name]
        }
    }
}

let obtainedLocalStorage : Storage
let obtainedSessionStorage : Storage
try {
    obtainedLocalStorage = localStorage
} catch(e) {}
try {
    obtainedSessionStorage = sessionStorage
} catch(e) {}

//@ts-ignore
export const sharedStorageLocal: SharedStorage = new SharedStorage( obtainedLocalStorage )
//@ts-ignore
export const sharedStorageSession: SharedStorage = new SharedStorage( obtainedSessionStorage )
